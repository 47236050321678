<template>
    <div class="home"  age="14">
        <Header />
        <div class="main_content">
            <div class="problem">
                <div class="title_item">
                    <img src="../assets/image/hot.png" alt="">
                    <p class="title_detail">熱門</p>
                </div>
                <div class="problemContent">
                    <van-collapse v-model="activeName" accordion>
                        <van-collapse-item  v-for="(item, index) in commonProblemList[1]" :key="index" :title="item.title" :name="item.id">{{item.detail}}</van-collapse-item>
                    </van-collapse>
                </div>
            </div>
            <div class="problem">
                <div class="title_item">
                    <img src="../assets/image/money.png" alt="">
                    <p class="title_detail">储值</p>
                </div>
                <div class="problemContent">
                    <van-collapse v-model="activeName2" accordion>
                        <van-collapse-item  v-for="(item, index) in commonProblemList[2]" :key="index" :title="item.title" :name="item.id">{{item.detail}}</van-collapse-item>
                    </van-collapse>
                </div>
            </div>
            <div class="problem">
                <div class="title_item">
                    <img src="../assets/image/version.png" alt="">
                    <p class="title_detail">版本更新</p>
                </div>
                <div class="problemContent">
                    <van-collapse v-model="activeName3" accordion>
                        <van-collapse-item  v-for="(item, index) in commonProblemList[3]" :key="index" :title="item.title" :name="item.id">{{item.detail}}</van-collapse-item>
                    </van-collapse>
                </div>
            </div>
        </div>
        <FootNav  :activeIndex="active"/>
    </div>
  </template>
  
  <script>
  import Header from "../components/Header.vue";
  import FootNav from "../components/FootNav.vue";

export default {
    components: {
      Header,
      FootNav,
    },
    data() {
        return {
            active:3,
            activeName: '1',
            activeName2: '1',
            activeName3: '1',
            commonProblemList:[]
        };
    },
    created()
    {
        this.getCommonProblem()
    },
    methods: {
        getCommonProblem(){
            this.service.post('Home/commonProblem')
            .then((res) => {
                this.commonProblemList = res.data.data
            }) 

        },
    },
};
  
  </script>
  
<style>
.problem{
    margin-top: 20px;
    padding: 0px 10px;
}
.problem .title_item {
    width: 100%;
    height: 24px;
    margin-bottom: 8px;
    font-size: 16px;
}
.problem .title_item img{
    float: left;
    width: 24px;
    height: 24px;
    margin-right: 2px;
}
.problem .title_item p{
    float: left;
    line-height: 24px;
    color: #ffffff;
}
.van-collapse-item{
    margin-bottom: 8px;
}
.van-cell{
    background:linear-gradient(to bottom,#FFE13D,#ffcb3c);
    box-shadow: (inset 2px 50 0px 50px #FFF193);
    height: 30px;
    line-height: 30px;
    padding: 3px 14px;
    color: #DC5823;
    font-size: 14px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: bold;
}

.van-collapse-item__content{
    font-size: 13px;
    color: #B09C6A;
    line-height: 20px;
    background-color: #FFF9A9;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
}
.van-collapse-item__title--expanded i{
    color: #FFFFFF;
}
.van-collapse-item__wrapper{
    background-color: #FFF9A9;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.van-collapse-item__title--expanded .van-cell__right-icon{
    color: #FFFFFF;
    font-size: 15px;

}
.van-collapse-item__title--expanded .van-cell__right-icon::before{
    content: '\e60a';

}
.van-collapse-item__title--expanded::after{
    content: "\e649";

}
.van-cell__right-icon{
    color: #FFFFFF;
}
.van-icon-arrow::before{
    color: #FFFFFF;
    content: "\e60a";
    font-size: 15px;
}
.van-hairline--top::after{
    border-top-width:0px;
}
.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after{
    border-width:0px;
}
</style>
  